import React, { useCallback, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const EnhancedPhoneInput = ({ 
  value, 
  onChange, 
  className,
  inputProps = {},
  searchClass,
  ...rest
}) => {
  const phoneInputRef = useRef(null);
  const containerRef = useRef(null);

  const handleDropdownOpen = useCallback(() => {
    if (/android/i.test(navigator.userAgent)) {
      const parentModals = document.querySelectorAll('.modal-container, .modal');
      parentModals.forEach(modal => {
        modal.style.overflow = 'hidden';
      });
      
      if (phoneInputRef.current?.numberInputRef) {
        phoneInputRef.current.numberInputRef.blur();
      }
    }
  }, []);

  const handleDropdownClose = useCallback(() => {
    if (/android/i.test(navigator.userAgent)) {
      const parentModals = document.querySelectorAll('.modal-container, .modal');
      parentModals.forEach(modal => {
        modal.style.overflow = 'auto';
      });
    }
  }, []);

  useEffect(() => {
    const phoneInput = phoneInputRef.current;
    if (!phoneInput) return;

    phoneInput.setState({ 
      ...phoneInput.state,
      onDropdownOpen: handleDropdownOpen,
      onDropdownClose: handleDropdownClose 
    });

    return () => {
      const parentModals = document.querySelectorAll('.modal-container, .modal');
      parentModals.forEach(modal => {
        modal.style.overflow = 'auto';
      });
    };
  }, [handleDropdownOpen, handleDropdownClose]);

  return (
    <div ref={containerRef} className="relative">
      <PhoneInput
        ref={phoneInputRef}
        autoFormat={true}
        enableSearch={true}
        disableScrollLock={false}
        containerClass="phone-input-container"
        className={className}
        inputProps={{
          ...inputProps,
          autoFocus: false,
        }}
        searchClass={searchClass}
        value={value}
        onChange={onChange}
        {...rest}
      />
      <style jsx global>{`
        @media (max-width: 767px) {
          .phone-input-container {
            position: relative;
          }
          .country-list {
            position: fixed !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            max-height: 30vh !important;
            width: 90vw !important;
            z-index: 9999 !important;
          }
        }
      `}</style>
    </div>
  );
};

export default EnhancedPhoneInput;
