const IS_NUDE_NEWS = window.location.hostname.includes('nudenews');

export const BASE_URL = IS_NUDE_NEWS ? process.env.REACT_APP_NUDENEWS_BACKEND : process.env.REACT_APP_NAKEDNEWS_BACKEND;
export const EPAY_URL = process.env.REACT_APP_EPAY_BACKEND_URL;
export const MEDIA_URL = 'https://rebelnerds-kubernetes.smart-x.net'; // is no longer used // deprecated

export const CC_UPDATE_URL = `${EPAY_URL}/rest/v1/users/user/profile/nakednews`;

// Blogs Page urls
export const BLOGS_LIST = `${BASE_URL}/rest/v1/blog/preview?page={page}&size={size}`;
export const BLOGS_LIST_BY_TAG = `${BASE_URL}/rest/v1/blog/tag/{tag}?page={page}&size={size}`;
export const BLOG_POST = `${BASE_URL}/rest/v1/blog?id={id}`;
export const CATEGORY_LIST_URL = `${BASE_URL}/rest/v1/blog/category`;
export const BLOG_LIKE = `${BASE_URL}/rest/v1/blog/like`;

// Tempted backend
export const TEMPTED_BACKEND = process.env.REACT_APP_TEMPTED_BACKEND_URL;
export const TEMPTED_DOMAIN = process.env.REACT_APP_TEMPTED_DOMAIN_URL;
// Home Page urls
// featured items
export const FEATURED_LIST_ALL = `${BASE_URL}/rest/v1/featured?page={page}&size={size}`;
export const FEATURED_LIST_ALL_V2 = `${BASE_URL}/rest/v2/featured?page={page}&size={size}`;
export const FEATURED_LIST_SEGMENTS = `${BASE_URL}/rest/v1/featured/segments?page={page}&size={size}`;
export const FEATURED_LIST_AUDITIONS = `${BASE_URL}/rest/v1/featured/auditions?page={page}&size={size}`;
export const FEATURED_LIST_BLOGPOSTS = `${BASE_URL}/rest/v1/featured/blogposts?page={page}&size={size}`;

// featured main program
export const FEATURED_MAIN_PROGRAM = `${BASE_URL}/rest/v1/program/featured`;
export const FEATURED_MAIN_PROGRAM_GUEST = `${BASE_URL}/rest/v1/program/featured/guest`;

// Anchors Page urls
export const ANCHOR_LIST = `${BASE_URL}/rest/v1/anchor/preview`;

// Anchor Page urls
export const ANCHOR_SERVICES_URL = `${TEMPTED_BACKEND}/rest/v2/anchorportal/{anchorName}`;
export const ANCHOR_SUMMARY_TEM = `${TEMPTED_BACKEND}/rest/v1/anchor/{username}/summary`;
export const ANCHOR_ACTIVE_STREAM_TEM = '{streamServiceUrl}/streams/{username}/active';
export const ANCHOR_VIEW = `${BASE_URL}/rest/v1/anchor/{id}`;
export const NNLIVE_VIEW = `${TEMPTED_BACKEND}/rest/v1/anchor/NakedNewsLive/nickname`;
export const ANCHOR_VIDEOS_ARCHIVE = `${TEMPTED_BACKEND}/rest/v1/anchor/{id}/video`;
export const ANCHOR_FANS_LIST = `${BASE_URL}/rest/v1/anchor/{id}/fans`;

export const ANCHOR_UPDATE_PROMO_IMAGE = `${BASE_URL}/rest/v1/anchor/{id}/pictures/{type}`;
export const ANCHOR_FEATURED_LIST_SEGMENTS = `${BASE_URL}/rest/v1/anchor/{id}/segment?page={page}&size={size}`;
export const ANCHOR_FEATURED_LIST_AUDITIONS = `${BASE_URL}/rest/v1/anchor/{id}/audition?page={page}&size={size}`;
export const ANCHOR_FEATURED_LIST_GALLERIES = `${BASE_URL}/rest/v1/anchor/{id}/galleries?page={page}&size={size}`;
export const ANCHOR_FEATURED_LIST_CLIPSTORE_ITEMS_FOR_SALE = `${TEMPTED_BACKEND}/rest/v3/temptations/{id}/notPurchasedClipStoreItems?isThumbnailSfwOnly=false&page={page}&size={size}`;
export const ANCHOR_FEATURED_ALL_CLIPSTORE_ITEMS_FOR_SALE = `${TEMPTED_BACKEND}/rest/v3/temptations/alpha/notPurchasedClipStoreItems?isThumbnailSfwOnly=false&page={page}&size={size}`;
export const ANCHOR_FEATURED_LIST_CLIPSTORE_ITEMS_PURCHASED = `${TEMPTED_BACKEND}/rest/v2/temptations/{id}/purchasedClipStoreItems?page={page}&size={size}`;
export const ANCHOR_FEATURED_ALL_CLIPSTORE_ITEMS_PURCHASED = `${TEMPTED_BACKEND}/rest/v2/temptations/alpha/purchasedClipStoreItems?page={page}&size={size}`;
export const ANCHOR_VIEW_PURCHASED_CLIPSTORE_GALLERY = `${TEMPTED_BACKEND}/rest/v2/temptations/{id}/purchasedGalleries/{galleryId}/view`;
export const ANCHOR_VIEW_PURCHASED_CLIPSTORE_VIDEO = `${TEMPTED_BACKEND}/rest/v2/temptations/{id}/purchasedVideos/{videoId}/view`;
export const CLIPSTORE_GALLERY_PAY_INFO = `${TEMPTED_BACKEND}/rest/v3/temptations/{id}/clipStoreGalleries/{clipId}/purchaseInfo?tenant=nakednews`;
export const CLIPSTORE_VIDEO_PAY_INFO = `${TEMPTED_BACKEND}/rest/v3/temptations/{id}/clipStoreVideos/{clipId}/purchaseInfo?tenant=nakednews`;
export const AUDIO_MESSAGES_PAY_INFO = `${TEMPTED_BACKEND}/rest/v2/paidFeaturePasses/{userId}/purchaseInfo/{anchorId}?tenant=nakednews`;
export const ANCHOR_STREAMS_V2_BASE = `${MEDIA_URL}/streaming-service/rest/v2/streams`;
export const ANCHOR_STREAMS_V2 = `${MEDIA_URL}/streaming-service/rest/v2/streams/{anchorUsername}`;
export const ANCHOR_ACTIVE_STREAM = `${MEDIA_URL}/streaming-service/rest/v1/nn/streams/{anchorUsername}?onlyActive=true`;
export const ANCHOR_EDGES_URL = `${MEDIA_URL}/streaming-service/rest/v1/nn/streams/{anchorUsername}/{uuid}/edges`;
export const ANCHOR_VIDEO_URL = `${MEDIA_URL}/streaming-service/rest/v1/broadcasts/nakedNews/streams/{broadcasterUsername}`;
export const ANCHOR_BROADCAST_STATUS = `${TEMPTED_BACKEND}/rest/v1/onlineAnchors?importedOnly=true`;

export const ANCHOR_VIDEOS_AND_GALLERIES = `${TEMPTED_BACKEND}/rest/v2/temptations/{anchorId}/availableMediaItems`;
export const ANCHOR_VIDEOS_ARCHIVE_VIDEO = `${TEMPTED_BACKEND}/rest/v2/temptations/{id}/publicVideos/{videoId}`;
export const ANCHOR_VIDEOS_AND_GALLERIES_COUNT = `${TEMPTED_BACKEND}/rest/v2/temptations/{id}/availableMediaItems/total`;
export const ALL_ANCHOR_MEDIA_TOTALS = `${TEMPTED_BACKEND}/rest/v2/temptations/alpha/availableMediaItems/total`;
export const ANCHOR_FEATURED_LIST_CLIPSTORE_ITEMS_EWALLET = `${TEMPTED_BACKEND}/rest/v2/temptations/{anchorId}/ewalletPurchases`;

// anchor private galleries
export const ANCHOR_PRIVATE_GALLERIES = `${TEMPTED_BACKEND}/rest/v1/anchor/{id}/privateGalleries`;
export const ANCHOR_PRIVATE_GALLERY = `${TEMPTED_BACKEND}/rest/v2/temptations/{id}/publicGalleries/{galleryId}`;

export const ANCHOR_PRIVATE_GALLERY_IMAGES = `${ANCHOR_PRIVATE_GALLERY}/images/`;
export const ANCHOR_PRIVATE_GALLERY_IMAGE = `${ANCHOR_PRIVATE_GALLERY}/images/{imageId}`;
export const ANCHOR_PRIVATE_GALLERY_COVER = `${ANCHOR_PRIVATE_GALLERY}/cover/{imageId}`;


// Arhive Page urls
export const ARHIVE_LIST = `${BASE_URL}/rest/v1/program?page={page}&size={size}`;
export const ARHIVE_LIST_BY_SEGMENT = `${BASE_URL}/rest/v1/program/segment/{id}?page={page}&size={size}`;
export const ARHIVE_LIST_BY_ANCHOR = `${BASE_URL}/rest/v1/program/anchor/{id}?page={page}&size={size}`;
export const ARHIVE_LIST_BY_VINTAGE = `${BASE_URL}/rest/v1/vintage/clip/vintage/{id}?page={page}&size={size}`;
export const ARHIVE_LIST_BY_DATE = `${BASE_URL}/rest/v1/program/date?page={page}&size={size}&year={id}&month={month}`;
export const ARHIVE_LIST_BY_TEXT = `${BASE_URL}/rest/v1/program/programSegments?page={page}&size={size}&search={searchParam}`;
export const ARCHIVES_LIST_BY_TAGNAME = `${BASE_URL}/rest/v1/program/programSegment/tag/{tagName}`;

// SubNav
export const SEGMENTS_LIST = `${BASE_URL}/rest/v1/segment`;
export const VINTAGE_LIST = `${BASE_URL}/rest/v1/vintage`;
// ANCHOR_LIST is already defined in "Anchors Page urls"

// Account related
export const CREATE_ACCOUNT_URL = `${BASE_URL}/rest/v1/program/date?page={page}&size={size}&year={id}&month={month}`;
export const LIST_EPAY_PACKAGES_ITEM = `${EPAY_URL}/rest/v1/checkout/nakednews/en/ITEM`;
export const LIST_EPAY_PACKAGES = `${EPAY_URL}/rest/v1/checkout/nakednews/en/MEMBERSHIP`;
export const LIST_EPAY_PACKAGES_GIFT = `${EPAY_URL}/rest/v1/checkout/nakednews/en/GIFT`;
export const LIST_EPAY_PACKAGES_FANCLUB = `${EPAY_URL}/rest/v1/checkout/nakednews/en/FANCLUB?subCategory={anchorId}`;
export const LIST_EPAY_NAKEDNEWS_FANCLUB = `${EPAY_URL}/rest/v1/checkout/nakednews/en/FANCLUB`;
export const TEMPTED_SUBSCRIPTION_PRICE_FOR_ANCHOR = `${TEMPTED_BACKEND}/rest/v1/temptations/{anchorId}/subscriptionPrices/regular`;
export const LIST_ALL_EPAY_PRICES_FANCLUB = `${EPAY_URL}/rest/v1/checkout/nakednews/en/FANCLUB`;
export const LIST_EPAY_PACKAGES_CALENDAR = `${EPAY_URL}/rest/v1/checkout/nakednews/en/CALENDAR`;
export const LIST_EPAY_PACKAGES_PACK = `${EPAY_URL}/rest/v1/checkout/nakednews/en/PACK`;
export const CALENDAR_SHIPPING_FEE_URL = `${LIST_EPAY_PACKAGES_CALENDAR}?calendarType={calendarType}&shippingCountryCode={cc}&shippingZipCode={zc}`;
export const SEND_TO_PROCESSOR = `${EPAY_URL}/rest/v1/checkout/nakednews/en/{username}/{priceId}/{processorId}`;
export const CREDENTIALS_URL = `${EPAY_URL}/rest/v1/users/user/profile/nakednews/{username}/{processorId}`;
export const OAUTH_URL = `${BASE_URL}/rest/v1/oauth2/authorize`;
export const FANCLUB_PURCHASE_INFO_URL = `${TEMPTED_BACKEND}/rest/v1/temptations/imported/{anchorId}/fanclub/info?username={username}`;

export const FETCH_GENERATED_USERNAME = `${BASE_URL}/rest/v1/account`;
export const TEMPTED_ACCOUNTS_URL = `${TEMPTED_BACKEND}/rest/v1/accounts/`;
export const EMAIL_PREFERENCES_URL = `${BASE_URL}/rest/v1/account/preferences`;

// Video Player related
export const FETCH_VIDEO_SEGMENT_BY_ID = `${BASE_URL}/rest/v1/program/programSegment/{id}`;
export const FETCH_VIDEO_PROGRAM_BY_ID = `${BASE_URL}/rest/v1/program/{id}`;
export const FETCH_VIDEO_VINTAGE_BY_ID = `${BASE_URL}/rest/v1/vintage/clip/{id}`;
export const FETCH_VIDEO_AUDITION_BY_ID = `${BASE_URL}/rest/v1/audition/{id}`;
export const FETCH_VIDEO_AUDITION_PREVIEW_BY_ID = `${BASE_URL}/rest/v1/audition/{id}/preview`;
export const TRACK_SEGMENT_VIDEO_WATCH = `${BASE_URL}/rest/v1/tracking/programSegment/{programSegmentId}/watch`;

// Audition Page
export const FETCH_AUDITION_LIST = `${BASE_URL}/rest/v1/audition?page={page}&size={size}`;

// Audition Forms API
export const SEND_AUDITION_FORM = `${BASE_URL}/rest/v1/forms/audition`;
export const SEND_AFFILIATE_CONTACT_FORM = `${BASE_URL}/rest/v1/forms/affiliate`;

// Login
export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const STATUS_URL = `${BASE_URL}/status`;
export const TEMPTED_STATUS_URL = `${TEMPTED_BACKEND}/status`;
// Reset password
export const RESET_PASSWORD_URL = `${BASE_URL}/rest/v1/password/reset`;
export const NEW_PASSWORD_URL = `${BASE_URL}/rest/v1/password/reset/{token}`;

// Tour page
export const TOUR_IMAGES_URL = `${BASE_URL}/rest/v1/tour`;

// Validation
export const VALIDATION_URL = `${BASE_URL}/rest/v1/{object}/validate`;

// Contact page

export const CATEGORIES_URL = `${BASE_URL}/rest/v1/contact/categories`;
export const CONTACT_FORM_SUBMIT = `${BASE_URL}/rest/v1/contact`;


export const ROKU_SYNC_URL = `${BASE_URL}/api/roku/linkAccount`;

// Private messaging
export const GLOBAL_PRESENCE_TOKEN_URL = `${TEMPTED_BACKEND}/rest/v1/globalPresence/accessTokens`;
export const OPENED_CONVERSATIONS = `${TEMPTED_BACKEND}/rest/v1/conversations`;
export const LOAD_STARTED_CONVERSATIONS_FANS = `${TEMPTED_BACKEND}/rest/v2/conversations/filteredAndSortedForFan?includeEveThread=false`;
export const CONVERSATION_ACCESS_TOKEN = `${TEMPTED_BACKEND}/rest/v2/conversations/{name}/accessTokens`;
export const LIST_OF_ANCHORS_MEMBER_SUBSCRIBED_TO = `${TEMPTED_BACKEND}/rest/v1/subscriber/anchors`;
export const TEMPTED_WEB_CHAT_MESSAGING = `${TEMPTED_BACKEND}/rest/v2/conversations/{conversationName}/messages`;
export const TEMPTED_WEB_CHAT_AUDIO_MESSAGE = `${TEMPTED_BACKEND}/rest/v2/conversations/{conversationName}/audioMessages/resource`;
export const TEMPTED_WEB_CHAT_AUDIO_MESSAGE_NOTIFICATION = `${TEMPTED_BACKEND}/rest/v2/conversations/{conversationName}/audioMessages/messages`;

// Active anchors
export const LIST_OF_ACTIVE_ANCHORS_MEMBER_SUBSCRIBED_TO = `${BASE_URL}/rest/v1/subscriber/{id}/active-anchors`;
export const GET_ANCHOR_ID_BY_USERNAME = `${BASE_URL}/rest/v1/anchor?username={username}`;

// Insights
export const ANCHOR_INSIGHTS = `${BASE_URL}/rest/v1/anchor/{anchorId}/insights`;

// Campaigns
export const GET_ACTIVE_CAMPAIGN = `${BASE_URL}/rest/v1/campaigns/active`;

// Mass Messages
export const SEND_MASS_MESSAGE = `${TEMPTED_BACKEND}/rest/v1/conversations/massMessages`;

// Fan Zones
export const FANZONES = `${BASE_URL}/rest/v1/anchor/fanzones`;
export const CLIPSTORES = `${BASE_URL}/rest/v1/anchor/clipstore`;

// Live broadcast
export const UPDATE_STREAM_SFW_FLAG = `${TEMPTED_BACKEND}/rest/v1/streams/`;

// Modals
export const MODALS_DATA_URL = `${BASE_URL}/rest/v1/modals`;

// Countries for calendar shipping
export const COUNTRIES_URL = `${BASE_URL}/rest/v1/countries`;

export const CHECK_USER_EXISTS = `${BASE_URL}/rest/v1/email/validate?email={userEmailID}`;
export const NAKED_NEWS_LIVE_SCHEDULE = `${BASE_URL}/rest/v1/live-schedule`;
export const CHECK_PROMOTION_AVAILABLE_FOR_USER = `${BASE_URL}/rest/v1/promo`;

// Tulum Reality Show
export const TULUM_REALITY_SHOW_CHAT_ACCESS = `${TEMPTED_BACKEND}/rest/v1/firstActiveRealityShow/chatAccess`;
export const TULUM_REALITY_SHOW_STREAMS = `${TEMPTED_BACKEND}/rest/v1/firstActiveRealityShow/participants`;
export const TEMPTED_WEB_CHAT_ACCESS = `${TEMPTED_BACKEND}/rest/v1/publicChatMessages/{roomName}`;
export const ACTIVE_REALITY_SHOWS = `${TEMPTED_BACKEND}/rest/v1/realityShows/active`;

//  Payment Processor Selection
export const GET_PROCESSOR_TO_USE = `${TEMPTED_BACKEND}/rest/v1/paymentProcessor`;

export const CAM4_OAUTH = `${BASE_URL}/rest/v1/cam4oauth/details`;
export const CAM4_OAUTH_LOGIN = `${BASE_URL}/rest/v1/cam4oauth/login`;
export const CAM4_OAUTH_REDIRECT_URI = `${BASE_URL}/cam4oauth`;
export const ANNOUNCEMENTS = `${BASE_URL}/rest/v1/announcements`;
export const WORDPRESS_PRODUCTS = 'https://shop.nakednews.com/index.php/wp-json/wc/store/products?catalog_visibility=visible';
export const GET_EMAIL_FROM_HASH = `${BASE_URL}/rest/v1/email/get`;
export const CREATE_ACCOUNT_API_URL = `${BASE_URL}/rest/v1/account/create`;
export const SET_NEW_USER_PASSWORD_URL = `${BASE_URL}/rest/v1/password/set`;
export const FREEMIUM_USER_FEATURED_SEGMENT = `${BASE_URL}/rest/v1/program/featured/free`;
export const FREEMIUM_USER_ALL_FREE_CONTENT = `${BASE_URL}/rest/v1/program/featured/free/segments?page={page}&size={size}`;

export const SCHEDULED_SHOWS_URL = `${TEMPTED_BACKEND}/rest/v1/temptations/{temptationId}/calendarShows`;
export const SEND_LIKE_FOR_CONTENT = `${TEMPTED_BACKEND}/rest/v2/temptations/{temptationId}/mediaItems/{contentType}/{contentId}/likes`;

export const FAN_ALIAS_FOR_ANCHOR = `${TEMPTED_BACKEND}/rest/v1/temptations/{currentUserId}/displayUsernames`;
export const GET_PRODUCTS = `${BASE_URL}/rest/v1/products/{productType}`;
export const GET_PRODUCT_PURCHASE_INFO = `${BASE_URL}/rest/v1/products/purchaseInfo/{id}`;
export const GET_ORDER_INFO = `${BASE_URL}/rest/v1/products/orderInfo/{id}`;
export const GET_AVAILABLE_START_YEAR = `${BASE_URL}/rest/v1/program/availableYears`;
export const MARCH_MADNESS_CONTEST_URL = `${BASE_URL}/rest/v1/march-madness-contest`;
export const SEGMENT_LIKE = `${BASE_URL}/rest/v1/segment-like`;
export const NAKED_NEWS_STORE_URL = 'https://shop.nakednews.com?option=oauthredirect&app_name=nnstore';

export const TEMPTED_AUDIO_MESSAGE_PRICE = `${TEMPTED_BACKEND}/rest/v1/temptations/{anchorId}/subscriptionPrices/paidFeatures`;
export const TEMPTED_AUDIO_MESSAGE_PASS_AVAILABILITY = `${TEMPTED_BACKEND}/rest/v2/paidFeaturePasses/{userId}/unused/{anchorId}`;

export const TEMPTED_CONTENT_SHARED_PRIVATELY = `${TEMPTED_BACKEND}/rest/v1/privateContent?page={page}&size={size}`;
export const TEMPTED_CLIPSTORE_CONTENT_NEW = `${TEMPTED_BACKEND}/rest/v1/allNotPurchasedClipStoreItems?page={page}&size={size}&alphaTemptationsOnly=true`;
export const TEMPTED_CLIPSTORE_CONTENT_PURCHASED = `${TEMPTED_BACKEND}/rest/v2/allPurchasedClipStoreItems?page={page}&size={size}&alphaTemptationsOnly=true`;
export const AFFILIATE_SIGNUP_URL = `${BASE_URL}/rest/v1/affiliates`;
export const AFFILIATE_LOGIN_URL = `${BASE_URL}/rest/v1/affiliates/login`;
export const AFFILIATE_EMAIL_URL = `${BASE_URL}/rest/v1/affiliates/check-email`;
export const AFFILIATE_PASSWORD_RESET_URL = `${BASE_URL}/rest/v1/affiliates/password/reset`;
export const AFFILIATE_PASSWORD_SET_URL = `${BASE_URL}/rest/v1/affiliates/password/set`;

export const SSO_REDIRECT_SESSION = `${BASE_URL}/rest/v1/sso/session`;
export const SSO_SESSION_INFO = `${BASE_URL}/rest/v1/sso/user-info`;
export const sso_session_redirect_url = 'https://sso-service.fim.staging.smart-x.dev/v0/sessions?redirect_uri={redirectUri}';

export const FAN_VIDEO_UPLOAD_POST = `${TEMPTED_BACKEND}/rest/v2/fans/{userId}/videos/anchors/{anchorId}`;
export const FAN_VIDEO_UPLOADED_PATCH = `${TEMPTED_BACKEND}/rest/v2/fans/{userId}/videos/{videoId}/conversations/{conversationId}`;
export const FAN_VIDEO_VIEW_CONTENT = `${TEMPTED_BACKEND}/rest/v2/fans/{userId}/videos/{videoId}/presignedUrl`;
export const EMAIL_CHECK = `${TEMPTED_BACKEND}/rest/v1/accounts/email/check`;
export const EMAIL_VERIFY = `${TEMPTED_BACKEND}/rest/v1/accounts/verification/{token}`;

// Universal Login
export const CREATE_EMAIL_MAGIC_LINK = `${BASE_URL}/rest/v1/email-link`;
export const VERIFY_EMAIL_MAGIC_LINK = `${BASE_URL}/rest/v1/email-link/login`;
export const CREATE_SMS_LOGIN = `${BASE_URL}/rest/v1/sms/login`;
export const VERIFY_SMS_LOGIN = `${BASE_URL}/rest/v1/sms/verify?sso_session={token}`;
