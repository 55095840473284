import React, { useState, useRef, useEffect, useCallback } from 'react';
import LoginModalStore from '../../../store/LoginModalStore';

const OTP_INPUT_SIZE = 6;

function SMSCodeLogin({ styles, resetState, formatInput, value }) {
  const [otp, setOtp] = useState(Array(OTP_INPUT_SIZE).fill(''));
  const [disabled, setDisabled] = useState(true);
  const inputRefs = useRef([]);
  const [infoText, setInfoText] = useState('');
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleChange = useCallback((index, value) => {
    if (value.match(/^[0-9]$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < OTP_INPUT_SIZE - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  }, [otp]);

  const handleKeyDown = useCallback((index, event) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      if (otp[index] === '' && index > 0) {
        newOtp[index - 1] = '';
        setOtp(newOtp);
        inputRefs.current[index - 1].focus();
      } else {
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
  }, [otp]);

  const handleSubmit = useCallback(async() => {
    if (otp) {
      const otpCode = otp.join('');
      const response = await LoginModalStore.verifySmsLogin(otpCode);

      if (response?.error) {
        setInfoText('');
        setError(response?.error);
      }
    }
  }, [otp]);

  const startCountdown = () => {
    setCountdown(120); // Start countdown from 120 seconds
    setError('');
    intervalRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          setInfoText('');
          clearInterval(intervalRef.current);

          return 0;
        }

        return prev - 1;
      });
    }, 1000);
  };

  const handleSendSmsLogin = useCallback(() => {
    const phoneNumber = formatInput(value);
    LoginModalStore.createSmsLogin(phoneNumber);
    setInfoText('A new code has been sent');
    startCountdown(); // Start countdown when SMS is sent
  }, [value, formatInput]);

  useEffect(() => {
    setDisabled(otp.includes(''));
  }, [otp]);

  useEffect(() => () => clearInterval(intervalRef.current), []);

  return (
    <div className={styles.smsInputContainer}>
      <small>Please enter the code below:</small>
      <div className={styles.otpInputContainer}>
        {otp.map((value, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            autoComplete="off"
            autoCorrect="off"
            className={styles.otpInput}
            id={`input-field-${index}`}
            max="9"
            maxLength="1"
            min="0"
            name={`data-input-field-${index}`}
            spellCheck="false"
            type="text"
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </div>
      <p className={styles.green}>{infoText}</p>
      <p className={styles.red}>{error}</p>
      <p className={styles.countdown}>{countdown > 0 && `Send again in ${countdown}s`}</p>
      <span className={styles.retryContainer}>
        <p>Didn't receive?</p>
        <button disabled={countdown > 0} onClick={handleSendSmsLogin}>
          Send again
        </button>
      </span>
      <button className={styles.otpConfirmButton} disabled={disabled} onClick={handleSubmit}>
        Confirm
      </button>
      <div className={styles.wrongNumberContainer}>
        <button className={styles.wrongNumber} onClick={resetState}>Wrong phone number?</button>
      </div>
    </div>
  );
}

export default SMSCodeLogin;
